import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';
import '../style.scss';
import { DailyUsage, MeanSpeadandDailyUsage, Time } from '../types';

export interface MeanDailyUsage {
  date: string;
  value: number;
}

interface Props {
  meanDuration: MeanSpeadandDailyUsage | undefined;
  loading: boolean;
}

export const convertToTotalMinutes = (value: Time) =>
  Math.round(value.days * 24 * 60 + value.hours * 60 + value.minutes + value.seconds / 60);

const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return hours > 0
    ? `${hours}h${remainingMinutes > 0 ? `${remainingMinutes}min` : ''}`
    : `${remainingMinutes}min`;
};

const MeanDailyUsage: React.FC<Props> = ({ meanDuration, loading }): JSX.Element => {
  const { t } = useTranslation();
  const [averageDurationData, setAverageDurationData] = useState<MeanDailyUsage[]>([]);
  const [totalUsage, setTotalUsage] = useState<number | null>(null);
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    if (meanDuration) {
      setNoData(false);
      if (meanDuration.dailyUsage.length === 0) {
        setNoData(true);
        return;
      }
      const formattedData = meanDuration.dailyUsage.map((item: DailyUsage) => {
        const date = parseISO(item.date);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return {
          date: format(localDate, 'd MMM'),
          value: convertToTotalMinutes(item.value),
        };
      });

      const total = formattedData.reduce((sum, item) => sum + item.value, 0);
      setTotalUsage(Math.floor(total));

      setAverageDurationData(formattedData);
    }
  }, [meanDuration]);

  const CustomYAxisTick = (props: any) => {
    const { x, y, payload, index } = props;
    if (index === 0) return null;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} textAnchor="end" fill="#999" fontSize={12}>
          {formatDuration(payload.value)}
        </text>
      </g>
    );
  };

  return (
    <div className="componentBase mean-duration-container">
      <div className="mean-duration-header">
        <h5 style={{ margin: '12px', marginTop: '20px' }}>{t('meanUsage')}</h5>
        <div
          className="info-icon"
          title={`${t('meanDailyUsage')} \n\n${t('meanDailyUsageCalculus')}`}
        >
          <FontAwesomeIcon icon={faInfo} />
        </div>
        {!loading && !noData && (
          <div className="mean-duration-value">
            {' '}
            Total = {totalUsage ? formatDuration(totalUsage) : '-'}
          </div>
        )}
      </div>
      <hr />
      {loading && (
        <div style={{ marginLeft: '11vw', marginTop: '8vh' }}>
          <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
        </div>
      )}
      {!loading && !noData && (
        <ResponsiveContainer width="98%" height="70%">
          <AreaChart
            data={averageDurationData}
            margin={{ top: 30, right: 10, left: -5, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FF9F43" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#FF9F43" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} stroke="#f5f5f5" />
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#999', fontSize: 12 }}
              interval={1}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={<CustomYAxisTick />}
              domain={['dataMin', 'dataMax']}
              dx={-40}
            />
            <Tooltip
              content={({ payload }) => {
                if (payload && payload.length) {
                  return (
                    <div className="custom-tooltip">
                      <p>{formatDuration(payload[0].payload.value)}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#FF9F43"
              fillOpacity={1}
              fill="url(#colorValue)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
      {!loading && noData && (
        <div className="no-data">
          <p>{t('noDataToDisplay')}</p>
        </div>
      )}
    </div>
  );
};

export default MeanDailyUsage;
