import React, { useState, useRef } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ResourcesHandler from 'components/ResourcesHandler';
import { RootState } from 'services/store';
import { fetchOrganizations } from 'services/organizations/operations';
import { fetchUsers } from 'services/users/operations';
import Dashboard from 'components/dashboard/Dashboard';
import logo from '../images/logo.png';
import './style.scss';

interface Organization {
  id: string;
  name: string;
}

const DashboardPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const organizationId = useSelector((state: RootState) => state.login.organizationId);
  const organizations = useSelector((state: RootState) => state.organizations);
  const [currentOrganizationId, setCurrentOrganizationId] = useState<string>(organizationId || '');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const dashboardRef = useRef<HTMLDivElement | null>(null);

  const generatePDF = async () => {
    setLoading(true);
    if (!dashboardRef.current) return;

    const element = dashboardRef.current;

    try {
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('landscape', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvas = await html2canvas(element, {
        scale: 2,
        backgroundColor: '#ffffff',
        useCORS: true,
      });

      const imgData = canvas.toDataURL('image/png');

      pdf.addImage(imgData, 'PNG', 7, 10, pdfWidth * 1.45, pdfHeight * 1.2);

      pdf.addImage(logo, 'PNG', (pdfWidth - 40) / 2, pdfHeight - 10, 37, 4);
      pdf.save('dashboard.pdf');

      const today = new Date();
      const dateStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
      pdf.setFontSize(10);
      pdf.text(dateStr, (pdfWidth - 16) / 2, pdfHeight - 1);

      const pdfBlob = pdf.output('blob');
      const blobUrl = URL.createObjectURL(pdfBlob);
      window.open(blobUrl, '_blank');

      setLoading(false);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setLoading(false);
    }
  };

  const handleSelectOrganization = (orgId: string | null) => {
    if (orgId) {
      setCurrentOrganizationId(orgId);
    }
  };

  const getPage = () => (
    <div>
      <div className="dashboard-title">
        <h2 style={{ marginLeft: '10px' }}>
          {t('dashboard')} (
          {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })})
        </h2>
        {organizationId === '*' && (
          <Dropdown style={{ marginLeft: '10px' }} onSelect={handleSelectOrganization}>
            <Dropdown.Toggle id="dropdown-basic">
              {currentOrganizationId === '*'
                ? t('allOrg')
                : organizations.find((org) => org.id === currentOrganizationId)?.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item key="*" eventKey="*">
                {t('allOrg')}
              </Dropdown.Item>
              {organizations.map((org: Organization) => (
                <Dropdown.Item key={org.id} eventKey={org.id}>
                  {org.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Button
          variant="primary"
          onClick={generatePDF}
          style={{ marginLeft: '10px', marginBottom: '10px' }}
          title={t('exportPDF')}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faFilePdf} />
        </Button>
      </div>
      <div ref={dashboardRef}>
        <Dashboard organizationId={currentOrganizationId} />
      </div>
    </div>
  );

  return (
    <ResourcesHandler
      resources={[organizations]}
      resourceFetchers={[() => dispatch(fetchOrganizations()), () => dispatch(fetchUsers())]}
      getContents={getPage}
    />
  );
};

export default DashboardPage;
