import React, { useContext } from 'react';
import { t } from 'i18next';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AlternativeDinSelectionContext,
  AlternativeDinSelectionContextType,
} from '../../pages/DrugsPage';
import { Drug, DrugWarnings } from './types';
import { DrugDependencies } from './DrugsTable';

interface DrugRowProps {
  drug: Drug;
  canUpdate?: boolean;
  canDelete?: boolean;
  dependencies: DrugDependencies;
}

export const notValidatedDrugWarning = (drugId: string) => (
  <span className="flag-cell">
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={drugId}>{t('notValidatedDrug')}</Tooltip>}
      trigger={['hover', 'click']}
    >
      <FontAwesomeIcon icon="exclamation-triangle" color="#ffc107" />
    </OverlayTrigger>
  </span>
);

const DrugRow = ({ drug, canUpdate, canDelete, dependencies }: DrugRowProps): JSX.Element => {
  const { alternativeDinSelection, alternatives, setAlternatives } = useContext(
    AlternativeDinSelectionContext,
  ) as AlternativeDinSelectionContextType;
  return (
    <tr>
      {alternativeDinSelection && (
        <td>
          <Form.Check
            type="checkbox"
            checked={alternatives.includes(drug.DIN) || drug.id === dependencies.selectedDrugId}
            onChange={() => {
              if (alternatives.includes(drug.DIN)) {
                setAlternatives(alternatives.filter((din) => din !== drug.DIN));
              } else {
                setAlternatives([...alternatives, drug.DIN]);
              }
            }}
            disabled={drug.id === dependencies.selectedDrugId}
          />
        </td>
      )}
      <td>
        <Button
          variant="link"
          onClick={() => {
            dependencies.setSelectedDrugId(drug.id);
            dependencies.setShowDrugDetails(true);
          }}
          disabled={alternativeDinSelection}
        >
          {drug.DIN}
        </Button>
      </td>
      <td>
        {drug.name.toUpperCase()}{' '}
        {drug.warnings?.includes(DrugWarnings.NOT_VALIDATED) && notValidatedDrugWarning(drug.id)}
      </td>
      <td>{drug && drug.force ? drug.force.toUpperCase() : '-'}</td>
      <td>{drug ? drug.format : '-'}</td>
      {(canUpdate || canDelete) && (
        <td>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {canUpdate && (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  dependencies.setSelectedDrugId(drug.id);
                  dependencies.setShowUpdateDrug(true);
                }}
                disabled={alternativeDinSelection}
              >
                <FontAwesomeIcon icon="pen" />
              </Button>
            )}
            {canDelete && (
              <Button
                variant="outline-danger"
                onClick={() => {
                  dependencies.setSelectedDrugId(drug.id);
                  dependencies.setShowDeleteDrug(true);
                }}
                disabled={alternativeDinSelection}
              >
                <FontAwesomeIcon icon="trash" />
              </Button>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default DrugRow;
