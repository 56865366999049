import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { RootState } from 'services/store';
import { login, logout, refreshToken } from 'services/login/operations';
import links from 'utils/links';
import './style.scss';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { t } from 'i18next';

function LoginExpiration(): JSX.Element {
  const loggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const expirationTime = useSelector((state: RootState) => state.login.expirationTime);
  const dispatch = useDispatch<Dispatch<any>>();
  const [showAlertLoggedOut, setShowAlertLoggedOut] = useState(false);
  const [showAlertExpirationSoon, setShowAlertExpirationSoon] = useState<number | undefined>(
    undefined,
  );
  const history = useHistory();

  const extendSession = async () => {
    await refreshToken()(dispatch);
    setShowAlertExpirationSoon(undefined);
  };

  useEffect(() => {
    if (!expirationTime || !loggedIn) {
      setShowAlertExpirationSoon(undefined);
      return;
    }

    const interval = setInterval(async () => {
      const timeAtTheMoment = Date.now();
      const diffInSeconds = Math.floor((expirationTime.getTime() - timeAtTheMoment) / 1000);

      if (diffInSeconds < 0 && loggedIn) {
        clearInterval(interval);
        setShowAlertLoggedOut(true);
        setShowAlertExpirationSoon(undefined);
        await logout()(dispatch);
        history.replace(links.paths.login);
      } else if (diffInSeconds < 120 && loggedIn) {
        setShowAlertExpirationSoon(diffInSeconds);
      } else {
        setShowAlertExpirationSoon(undefined);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expirationTime, dispatch, loggedIn, history]);

  useEffect(() => {
    (async () => {
      try {
        const res = await login()(dispatch);
        if (
          res &&
          res.payload &&
          res.payload.error &&
          !window.location.href.endsWith(links.paths.login)
        ) {
          window.location.replace(links.paths.login);
        }
      } catch (err) {
        console.error('ERROR LOGIN', err);
      }
    })();
  }, [dispatch]);

  return (
    <>
      {showAlertExpirationSoon && (
        <Alert variant="primary">
          <Alert.Heading>{t('warning')}</Alert.Heading>
          <p>{t('sessionExpireSoon', { time: showAlertExpirationSoon })}</p>
          <hr />

          <Button onClick={extendSession}>{t('extend')}</Button>
        </Alert>
      )}

      <Modal show={showAlertLoggedOut} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>{t('sessionExpired')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('mustReconnect')}</Modal.Body>
        <Modal.Footer>
          <Link to={links.login()}>
            <Button onClick={() => setShowAlertLoggedOut(false)}>{t('returnToLogin')}</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginExpiration;
