import React, { useEffect, useState } from 'react';
import { faInfo, faClock, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import '../style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MeanSpeadandDailyUsage, Time } from '../types';

interface MeanSpeedDisplay {
  name: string;
  value: Time;
  error?: string;
  pillBoxPerHour: number;
}

interface Props {
  meanDuration: MeanSpeadandDailyUsage | undefined;
  loading: boolean;
}

const MeanSpeed: React.FC<Props> = ({ meanDuration, loading }): JSX.Element => {
  const { t } = useTranslation();
  const [meanSpeed, setMeanSpeed] = useState<MeanSpeedDisplay>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (meanDuration) {
      setError(false);
      if (meanDuration.error) {
        setError(true);
        return;
      }
      setMeanSpeed({
        name: t('meanSpeed'),
        value: meanDuration.meanSpeed.value,
        pillBoxPerHour: meanDuration.meanSpeed.pillBoxPerHour,
      });
    }
  }, [meanDuration, t]);

  return (
    <div className="componentBase mean-speed-container">
      <div
        className="info-icon"
        title={`${t('meanSpeedInfos')} \n\n${t('meanSpeedInfosCalculus')}`}
      >
        <FontAwesomeIcon icon={faInfo} />
      </div>
      {loading && (
        <div>
          <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
        </div>
      )}

      {!loading && error && (
        <div className="error-message-dashboard">
          <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: '38px' }} />
          {t('errorFetchingData')}
        </div>
      )}

      {!loading && !error && (
        <>
          <div className="mean-speed-icon">
            <FontAwesomeIcon icon={faClock} style={{ fontSize: '24px' }} />
          </div>
          <div className="time-sections">
            <div className="time-placeholder">
              {meanSpeed?.value.hours !== 0 && (
                <div className="time-entry">{meanSpeed?.value.hours} h</div>
              )}
              {meanSpeed?.value.minutes !== 0 && (
                <div className="time-entry">{meanSpeed?.value.minutes} min</div>
              )}
              {meanSpeed?.value.seconds !== 0 && (
                <div className="time-entry">{meanSpeed?.value.seconds} sec</div>
              )}
            </div>
            <div style={{ fontSize: '12px', color: '#555', fontWeight: 'bold' }}>
              {t('meanSpeed')}
            </div>
            <div style={{ fontSize: '10px', color: '#b1b1b1', fontWeight: 'bold' }}>
              ({meanSpeed?.pillBoxPerHour.toFixed(0)} {t('pillBoxPerHour')})
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MeanSpeed;
