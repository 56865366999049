import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'services/store';
import { Badge, FormControl, Table, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { unloadMultipleBuckets } from 'services/buckets/operations';
import { useTranslation } from 'react-i18next';
import '../style.scss';

interface UnloadMultipleModalProps {
  isOpen: boolean;
  onClose: () => void;
  buckets: any;
}

const UnloadMultipleModal = ({
  isOpen,
  onClose,
  buckets,
}: UnloadMultipleModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();
  const drugs = useSelector((state: RootState) => state.drugs);
  const [loading, setLoading] = useState<boolean>(false);
  const [unloadedBuckets, setUnloadedBuckets] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSelectAll = () => {
    if (unloadedBuckets.length === buckets.length) {
      setUnloadedBuckets([]);
    } else {
      const allBucketIds = filteredBuckets.map((bucket: any) => bucket.id);
      setUnloadedBuckets(allBucketIds);
    }
  };

  const handleUnload = async () => {
    try {
      setLoading(true);
      await unloadMultipleBuckets(unloadedBuckets)(dispatch);
      setLoading(false);
      setUnloadedBuckets([]);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddBucketList = (bucket: any) => {
    if (unloadedBuckets.includes(bucket.id)) {
      setUnloadedBuckets(unloadedBuckets.filter((id) => id !== bucket.id));
    } else {
      setUnloadedBuckets([...unloadedBuckets, bucket.id]);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredBuckets = [...buckets]
    .filter((bucket) => {
      const query = searchQuery?.trim();

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(parseInt(query, 10))) {
        if (query.length <= 4) {
          return bucket.position === query;
        }
        if (query.length === 8) {
          return bucket.DIN.includes(query);
        }

        if ((query.length === 12 || query.length === 13) && bucket.UPCs) {
          return bucket.UPCs.some((upc) => upc.includes(query));
        }
      }
      const drug = drugs.find((d) => d.DIN === bucket.DIN);
      if (!drug) return false;

      return drug.name.toLowerCase().includes(query.toLowerCase());
    })
    .sort((a, b) => parseInt(a.position, 10) - parseInt(b.position, 10));

  const rows = filteredBuckets.map((bucket) => {
    const drug = drugs.find((d) => d.DIN === bucket.DIN);

    const isUnloaded = unloadedBuckets.includes(bucket.id);

    return (
      <tr key={bucket.id}>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <Button
            variant={isUnloaded ? 'secondary' : 'danger'}
            size="sm"
            onClick={() => handleAddBucketList(bucket)}
          >
            {isUnloaded ? <FontAwesomeIcon icon={faTimes} /> : t('unload')}
          </Button>
        </td>
        <td>{bucket.position}</td>
        <td>{bucket.DIN}</td>
        <td>{drug?.name}</td>
        <td>{drug?.force}</td>
        <td>{drug?.format}</td>
      </tr>
    );
  });

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('multipleUnloadBucket')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex justify-content-left align-items-center mb-3"
          style={{ gap: '15px' }}
        >
          {!searchQuery && (
            <Button
              variant="info"
              onClick={handleSelectAll}
              className="btn-sm"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {unloadedBuckets.length !== buckets.length && (
                <FontAwesomeIcon icon={faCheckSquare} style={{ marginRight: '8px' }} />
              )}
              {unloadedBuckets.length === buckets.length ? t('deselectAll') : t('selectAll')}
            </Button>
          )}
          <div className="d-flex align-items-center" style={{ width: '30%' }}>
            <FormControl
              type="text"
              placeholder={`${t('search')}...`}
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div className="table-responsive unload-multiple-table">
          <Table bordered striped>
            <thead>
              <tr>
                <th style={{ width: 50 }}>Action</th>
                <th>Position</th>
                <th>{t('DIN')}</th>
                <th>{t('drug')}</th>
                <th>{t('strength')}</th>
                <th>{t('format')}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
        <h4>{t('listBucketToUnload')}</h4>
        <div className="bucket-badges">
          {unloadedBuckets.map((id) => {
            const bucket = buckets.find((b) => b.id === id);
            return (
              <Badge key={id} pill variant="primary" className="mr-2">
                {bucket?.DIN}
              </Badge>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={handleUnload} disabled={unloadedBuckets.length === 0}>
          {loading ? t('unloading') : t('unload')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnloadMultipleModal;
