import { Dispatch } from 'redux';
import { Drug } from 'components/drugs/types';
import {
  getDrugs as APIgetDrugs,
  createDrug as APIcreateDrug,
  updateDrug as APIupdateDrug,
  validateDrug as APIvalidateDrug,
  addDrugUPC as APIaddDrugUPC,
  validateDrugUPC as APIvalidateDrugUPC,
  invalidateDrugUPC as APIinvalidateDrugUPC,
  patchDrug as APIpatchDrug,
  deleteDrug as APIdeleteDrug,
  importDrugList as APIimportDrugList,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  createDrugRequest,
  createDrugResponse,
  getDrugsRequest,
  getDrugsResponse,
  updateDrugRequest,
  updateDrugResponse,
  addDrugUPCRequest,
  addDrugUPCResponse,
  patchDrugRequest,
  patchDrugResponse,
  deleteDrugRequest,
  deleteDrugResponse,
  validateDrugUPCRequest,
  validateDrugUPCResponse,
  invalidateDrugUPCRequest,
  invalidateDrugUPCResponse,
  importDrugListRequest,
  importDrugListResponse,
  validateDrugRequest,
  validateDrugResponse,
} from './actions';
import { DrugAction } from './reducers';

type DrugDispatch = Dispatch<DrugAction>;

export function fetchDrugs() {
  return fetchOnceOperation(
    getDrugsRequest,
    getDrugsResponse,
    APIgetDrugs,
    (state) => state.drugs,
    [],
  );
}

export function createDrug(drug: Omit<Drug, 'id'>) {
  return (dispatch: DrugDispatch) => {
    dispatch(createDrugRequest(drug));
    return APIcreateDrug(drug).then((res) => dispatch(createDrugResponse(res.drug)));
  };
}

export function updateDrug(id: string, drug: Drug) {
  return async (dispatch: DrugDispatch) => {
    dispatch(updateDrugRequest(id, drug));
    const res = await APIupdateDrug(id, drug);
    dispatch(updateDrugResponse({ ...res }));
  };
}

export function validateDrug(id: string) {
  return (dispatch: DrugDispatch) => {
    dispatch(validateDrugRequest());
    return APIvalidateDrug(id).then((res) => dispatch(validateDrugResponse({ ...res })));
  };
}

export function addDrugUPC(id: string, UPC: string) {
  return (dispatch: DrugDispatch) => {
    dispatch(addDrugUPCRequest(id, UPC));
    return APIaddDrugUPC(id, UPC).then((res) => dispatch(addDrugUPCResponse({ ...res })));
  };
}

export function validateDrugUPC(id: string, UPC: string) {
  return (dispatch: DrugDispatch) => {
    dispatch(validateDrugUPCRequest(id, UPC));
    return APIvalidateDrugUPC(id, UPC).then((res) => dispatch(validateDrugUPCResponse({ ...res })));
  };
}

export function invalidateDrugUPC(id: string, UPC: string) {
  return (dispatch: DrugDispatch) => {
    dispatch(invalidateDrugUPCRequest(id, UPC));
    return APIinvalidateDrugUPC(id, UPC).then((res) =>
      dispatch(invalidateDrugUPCResponse({ ...res })),
    );
  };
}

export function patchDrug(id: string, drug: Drug) {
  return (dispatch: DrugDispatch) => {
    dispatch(patchDrugRequest(id, drug));
    return APIpatchDrug(id, drug).then((res) => dispatch(patchDrugResponse({ ...res })));
  };
}

export function deleteDrug(id: string) {
  return (dispatch: DrugDispatch) => {
    dispatch(deleteDrugRequest(id));
    return APIdeleteDrug(id).then((res) => dispatch(deleteDrugResponse({ ...res })));
  };
}

export function importDrugList(drugList: Omit<Drug, 'id' | 'newUPC'>[]) {
  return (dispatch: DrugDispatch) => {
    dispatch(importDrugListRequest(drugList));
    return APIimportDrugList(drugList).then((res) => dispatch(importDrugListResponse(res.drugs)));
  };
}

const operations = {
  fetchDrugs,
  createDrug,
  updateDrug,
  patchDrug,
  deleteDrug,
  importDrugList,
};

export default operations;
