import React, { useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import { ErrorMessage, FieldArray, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import {
  AlternativeDinSelectionContext,
  AlternativeDinSelectionContextType,
} from 'pages/DrugsPage';
import { t } from 'i18next';
import { FormValues, SetShowDependencies } from '../DrugFormModal';

interface AlternativesDependencies {
  isUnknownSavedDIN: (DIN: string) => boolean;
  setNextInContextDIN: (DIN: string) => void;
  changeInContextDrugId: (drugId: string | null) => void;
}

interface AlternativesProps {
  handleBlur: FormikProps<FormValues>['handleBlur'];
  setFieldValue: FormikProps<FormValues>['setFieldValue'];
  values: FormikProps<FormValues>['values'];
  dependencies: AlternativesDependencies;
  setShow: SetShowDependencies;
}

function Alternatives({
  handleBlur,
  setFieldValue,
  values,
  dependencies,
  setShow,
}: AlternativesProps) {
  const { setAlternatives, setAlternativeDinSelection } = useContext(
    AlternativeDinSelectionContext,
  ) as AlternativeDinSelectionContextType;
  return (
    <Form.Group controlId="alternatives">
      <FieldArray
        name="alternatives"
        render={(arrayHelpers) => (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1%',
              }}
            >
              <Form.Label>{t('alternatives')}</Form.Label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Button
                  onClick={() => {
                    const newAlternatives = [...values.alternatives];
                    setFieldValue('alternatives', newAlternatives);
                    setAlternatives(newAlternatives);
                    arrayHelpers.push('');
                  }}
                  variant="outline-secondary"
                  size="sm"
                >
                  <FontAwesomeIcon icon="plus" />
                </Button>

                <Button
                  onClick={() => {
                    setAlternativeDinSelection(true);
                    setShow.setShowCreateDrug(false);
                    setShow.setShowUpdateDrug(false);
                  }}
                  variant="outline-secondary"
                  size="sm"
                  title={t('selectFromList')}
                >
                  <FontAwesomeIcon icon={faList} />
                </Button>
              </div>
            </div>
            {values.alternatives.map((alternative, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '1%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Form.Control
                    type="text"
                    name={`alternatives[${index}]`}
                    value={alternative}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const newAlternatives = [...values.alternatives];
                      newAlternatives[index] = event.target.value;
                      setFieldValue('alternatives', newAlternatives);
                      setAlternatives(newAlternatives);
                    }}
                  />
                  {dependencies.isUnknownSavedDIN(alternative) && (
                    <Button
                      variant="outline-success"
                      style={{ marginLeft: '1%' }}
                      onClick={() => {
                        dependencies.setNextInContextDIN(alternative);
                        dependencies.changeInContextDrugId(null);
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  )}
                  <Button
                    variant="outline-danger"
                    style={{ marginLeft: '1%' }}
                    onClick={() => {
                      const newAlternatives = values.alternatives.filter((_, i) => i !== index);
                      arrayHelpers.remove(index);
                      setFieldValue('alternatives', newAlternatives);
                      setAlternatives(newAlternatives);
                    }}
                    size="sm"
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </div>
                {alternative.length === 8 && dependencies.isUnknownSavedDIN(alternative) && (
                  <div style={{ color: 'orange' }}>{t('unknownDrug')}</div>
                )}
                <ErrorMessage
                  name={`alternatives[${index}]`}
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </div>
            ))}
          </div>
        )}
      />
    </Form.Group>
  );
}

export default Alternatives;
