import React, { useState } from 'react';
import './style.scss';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Modal, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { unloadBucket } from 'services/buckets/operations';
import { HttpResponseError } from 'services/api';
import { RootState } from 'services/store';
import { LoadingButton } from 'components/shared/LoadingButton';
import { Bucket } from '../types';
import RefillForm from './RefillForm';
import LotTable from './LotTable';
import { getRecommandedQty } from '../BucketRow';

interface BucketModalProps {
  isOpen: boolean;
  bucket: Bucket;
  onClose: () => void;
}

function SingleRefillModal({ isOpen, bucket, onClose }: BucketModalProps) {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const UNLOAD_BUCKET_DELAY = 2000;
  const [bucketUnloading, setBucketUnloading] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('refillForm');
  const drugs = useSelector((state: RootState) => state.drugs);

  const startUnloading = async (bucketId: string) => {
    try {
      setBucketUnloading(bucketId);
      await unloadBucket(bucketId)(dispatch);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        setError(content?.error?.message || t('errorOccurred'));
        return;
      }

      console.error('ERROR UNLOADING BUCKET', err);
    } finally {
      setTimeout(() => {
        setBucketUnloading(null);
      }, UNLOAD_BUCKET_DELAY);
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} className="refill-buckets-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {t('refill')}
          <div className="single-refill-title">
            <div className="single-refill-text">
              <br /> {t('bucket')} #{bucket.position}:{' '}
              {drugs.find((d) => d.DIN === bucket.DIN)?.name?.toUpperCase()}{' '}
              {drugs.find((d) => d.DIN === bucket.DIN)?.force?.toUpperCase()} (DIN {bucket.DIN}){' '}
              <div style={{ fontSize: '50%' }}>
                {`${t('recommendedQty')}: ${getRecommandedQty(bucket)}`}
              </div>
            </div>
            <LoadingButton
              variant="primary"
              onClick={() => startUnloading(bucket.id)}
              loading={bucketUnloading === bucket.id}
              disabled={bucketUnloading === bucket.id}
            >
              {t('unload')}
            </LoadingButton>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!error && <Alert>{t('error')}</Alert>}
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k ?? 'refillForm')}>
          <Tab eventKey="refillForm" title={t('addLot')}>
            <RefillForm
              show={isOpen}
              bucket={bucket}
              drugs={drugs}
              submitDisabled={false}
              submitComplete={onClose}
            />
          </Tab>
          <Tab eventKey="lotTable" title="Lots">
            <LotTable bucket={bucket} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default SingleRefillModal;
