import React, { useState, createContext } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DrugsTable from 'components/drugs/DrugsTable';
import { fetchDrugs, deleteDrug } from 'services/drugs/operations';
import { fetchUsers } from 'services/users/operations';
import { RootState } from 'services/store';
import { Button } from 'react-bootstrap';
import ImportDrugListModal from 'components/drugs/ImportDrugListModal';
import DrugFormModal from 'components/drugs/DrugForm/DrugFormModal';
import DrugDetailsModal from 'components/drugs/DrugDetailsModal';
import ConfirmationModal from 'components/shared/ConfirmationModal/ConfirmationModal';

export interface AlternativeDinSelectionContextType {
  alternativeDinSelection: boolean;
  setAlternativeDinSelection: React.Dispatch<React.SetStateAction<boolean>>;
  alternatives: string[];
  setAlternatives: React.Dispatch<React.SetStateAction<string[]>>;
}

export const AlternativeDinSelectionContext = createContext<AlternativeDinSelectionContextType>({
  alternativeDinSelection: false,
  setAlternativeDinSelection: () => {},
  alternatives: [],
  setAlternatives: () => {},
});

const DrugsPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const drugs = useSelector((state: RootState) => state.drugs);
  const user = useSelector((state: RootState) => state.login);
  const { t } = useTranslation();

  const [alternativeDinSelection, setAlternativeDinSelection] = useState<boolean>(false);
  const [alternatives, setAlternatives] = useState<string[]>([]);
  const [selectedDrugId, setSelectedDrugId] = useState<string | null>(null);
  const [showImportDrugList, setShowImportDrugList] = useState<boolean>(false);
  const [showCreateDrug, setShowCreateDrug] = useState<boolean>(false);
  const [showDrugDetails, setShowDrugDetails] = useState<boolean>(false);
  const [showUpdateDrug, setShowUpdateDrug] = useState<boolean>(false);
  const [showDeleteDrug, setShowDeleteDrug] = useState<boolean>(false);

  const handleDeleleDrug = async () => {
    try {
      if (selectedDrugId) await deleteDrug(selectedDrugId)(dispatch);
    } catch (err) {
      console.error(err);
    } finally {
      setShowDeleteDrug(false);
      setSelectedDrugId(null);
    }
  };

  const deleteConfirmationMessage = (drugId: string | null): JSX.Element => {
    const drug = drugs.find((drug) => drug.id === drugId);
    return (
      <>
        {drug && (
          <>
            <p>{t('deleteDrugConfirmation')}</p>
            <p style={{ textAlign: 'center' }}>
              <strong>{drug.DIN}</strong> - <strong>{drug.name.toUpperCase()}</strong> -{' '}
              <strong>{drug.force ? drug.force.toUpperCase() : ''}</strong>
            </p>
          </>
        )}
      </>
    );
  };

  const getPage = () => (
    <>
      <AlternativeDinSelectionContext.Provider
        value={{
          alternativeDinSelection,
          setAlternativeDinSelection,
          alternatives,
          setAlternatives,
        }}
      >
        <h2>{t('drugs')}</h2>
        <div
          style={{
            float: 'right',
            marginBottom: 20,
            cursor: 'pointer',
          }}
        >
          {user?.permissions?.includes('drugs.create') && (
            <>
              <Button
                className="mr-2"
                variant="outline-secondary"
                onClick={() => setShowImportDrugList(true)}
                disabled={alternativeDinSelection}
              >
                <FontAwesomeIcon icon="file-import" />
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => setShowCreateDrug(true)}
                disabled={alternativeDinSelection}
              >
                <FontAwesomeIcon icon="plus" />
              </Button>
            </>
          )}
        </div>
        <ImportDrugListModal show={showImportDrugList} close={() => setShowImportDrugList(false)} />
        <DrugFormModal
          show={showCreateDrug || showUpdateDrug}
          setShow={{
            setShowUpdateDrug,
            setShowCreateDrug,
          }}
          close={() => {
            setShowCreateDrug(false);
            setShowUpdateDrug(false);
            setAlternatives([]);
            setSelectedDrugId(null);
          }}
          drugId={selectedDrugId}
          changeInContextDrugId={setSelectedDrugId}
        />
        {selectedDrugId && (
          <DrugDetailsModal
            show={showDrugDetails}
            close={() => setShowDrugDetails(false)}
            onExited={() => setSelectedDrugId(null)}
            drugId={selectedDrugId}
          />
        )}

        <ConfirmationModal
          show={showDeleteDrug}
          onHide={() => {
            setSelectedDrugId(null);
            setShowDeleteDrug(false);
          }}
          onConfirm={handleDeleleDrug}
          message={deleteConfirmationMessage(selectedDrugId)}
        />
        <DrugsTable
          selectedDrugId={selectedDrugId}
          setSelectedDrugId={setSelectedDrugId}
          setShowDrugDetails={setShowDrugDetails}
          setShowUpdateDrug={setShowUpdateDrug}
          setShowDeleteDrug={setShowDeleteDrug}
        />
      </AlternativeDinSelectionContext.Provider>
    </>
  );

  return (
    <ResourcesHandler
      resources={[drugs]}
      resourceFetchers={[() => dispatch(fetchDrugs()), () => dispatch(fetchUsers())]}
      getContents={getPage}
    />
  );
};

export default DrugsPage;
