enum Types {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_RESPONSE = 'LOGIN_RESPONSE',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_RESPONSE = 'LOGOUT_RESPONSE',
  NEW_EXPIRATION_TIME = 'NEW_EXPIRATION_TIME',
  VERIFY_2FA_REQUEST = 'VERIFY_2FA_REQUEST',
  VERIFY_2FA_RESPONSE = 'VERIFY_2FA_RESPONSE',
  ENABLE_2FA_REQUEST = 'ENABLE_2FA_REQUEST',
  ENABLE_2FA_RESPONSE = 'ENABLE_2FA_RESPONSE',
  REFRESH_2FA_REQUEST = 'REFRESH_2FA_REQUEST',
  REFRESH_2FA_RESPONSE = 'REFRESH_2FA_RESPONSE',
  EXTEND_SESSION_REQUEST = 'EXTEND_SESSION_REQUEST',
  EXTEND_SESSION_RESPONSE = 'EXTEND_SESSION_RESPONSE',
}

export default Types;
