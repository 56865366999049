import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { logout } from 'services/login/endpoints';
import links from 'utils/links';
import {
  useCheckPermissions,
  checkIsAdmin,
  checkIsSuperTech,
  checkIsChronoTech,
} from 'utils/permissions';
import logoBlanc from 'images/logoBlanc.png';
import { UserPermissions } from './users/types';
import 'react-pro-sidebar/dist/css/styles.css';
import './SideMenu.scss';

interface SideMenuProps {
  toggled?: boolean;
  onToggle: () => void;
}

function SideMenu({ toggled, onToggle }: SideMenuProps) {
  const users = useSelector((state: RootState) => state.users);
  const user = useSelector((state: RootState) => state.login);
  const userFirstName = users?.find((u) => u.id === user.userId)?.firstName;
  const { t } = useTranslation();
  const loginState = useSelector((state: RootState) => state.login);
  const isAdmin = checkIsAdmin(loginState) || checkIsChronoTech(loginState);
  const isSuperTech = checkIsSuperTech(loginState);

  const doLogout = async (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    await logout();
    document.location.reload();
  };

  return (
    <ProSidebar breakPoint="xl" toggled={toggled} onToggle={onToggle}>
      <SidebarHeader>
        <Link to={links.home()}>
          <img
            src={logoBlanc}
            alt="chronomed.ca"
            width={200}
            style={{
              marginLeft: 20,
              marginTop: 30,
            }}
          />
        </Link>
        <div
          style={{
            marginLeft: 20,
            marginTop: 20,
            marginBottom: 10,
            color: 'white',
            fontWeight: 500,
            minHeight: '30px',
          }}
        >
          {userFirstName && `${t('hello')},`}{' '}
          <span style={{ color: '#6FD1C9' }}>{userFirstName}</span>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square">
          <MenuItem>
            {t('buckets')}
            <Link to={links.buckets} onClick={onToggle} />
          </MenuItem>
          {useCheckPermissions(UserPermissions.DRUGS_LIST) && isAdmin && (
            <MenuItem>
              {t('drugs')}
              <Link to={links.drugs} onClick={onToggle} />
            </MenuItem>
          )}
          <MenuItem>
            {t('orders')}
            <Link to={links.orders()} onClick={onToggle} />
          </MenuItem>

          {useCheckPermissions(UserPermissions.ORGANIZATIONS_LIST) && isAdmin && (
            <MenuItem>
              {t('organizations')}
              <Link to={links.organizations()} onClick={onToggle} />
            </MenuItem>
          )}
          {useCheckPermissions(UserPermissions.USERS_LIST) && (isAdmin || isSuperTech) && (
            <MenuItem>
              {t('users')}
              <Link to={links.users()} onClick={onToggle} />
            </MenuItem>
          )}
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu>
          {/* <MenuItem>
            <FontAwesomeIcon icon="file" style={{ marginRight: 19 }} />
            {t('userGuide')}
            <Link to={links.userGuide()} onClick={onToggle} />
          </MenuItem> */}
          {useCheckPermissions(UserPermissions.ORGANIZATIONS_DASHBOARD) && (
            <MenuItem>
              <FontAwesomeIcon icon="chart-line" style={{ marginRight: 15 }} />
              {t('dashboard')}
              <Link to={links.dashboard()} onClick={onToggle} />
            </MenuItem>
          )}
          <MenuItem>
            <FontAwesomeIcon icon="cog" style={{ marginRight: 15 }} />
            {t('settings')}
            <Link to={links.settings()} onClick={onToggle} />
          </MenuItem>
          <MenuItem>
            <FontAwesomeIcon icon="power-off" style={{ marginRight: 15 }} />
            {t('logout')}
            <Link to="/logout" onClick={doLogout} />
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
}

export default SideMenu;
