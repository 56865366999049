import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BucketsPage from 'pages/BucketsPage';
import DrugsPage from 'pages/DrugsPage';
import OrdersPage from 'pages/OrdersPage';
import DashboardPage from 'pages/DashboardPage';
import ReportPage from 'pages/ReportPage';
import OrganizationsPage from 'pages/OrganizationsPage';
import SettingsPage from 'pages/SettingsPage';
import UsersPage from 'pages/UsersPage';
import UserGuidePage from 'pages/UserGuidePage';
import Page404 from 'pages/errorPages/Page404';
import links from 'utils/links';
import { RootState } from 'services/store';
import { getSocketAsync } from 'services/sockets';
import RefillBucketsModal from 'components/buckets/RefillModals/RefillBucketsModal';
import ExpiredDrugsModal from 'components/buckets/ExpiredDrugsModal/ExpiredDrugsModal';
import { UserPermissions } from 'components/users/types';
import LoginPage from '../Login/Login';
import PrivateRoute from '../PrivateRoute';
import { TopMenu } from '../UI/TopMenu';
import SideMenu from '../SideMenu';
import LoginExpiration from './LoginExpiration';
import LotExpireSoon from './LotExpiringSoon';

import './style.scss';

function Layout(): JSX.Element {
  const user = useSelector((state: RootState) => state.login);
  const [toggled, setToggled] = useState(false);
  const [toRefill, setToRefill] = useState<string[]>([]);
  const [expiredDrugs, setExpiredDrugs] = useState<string[]>([]);

  useEffect(() => {
    const waitSocket = async () => {
      const socket = await getSocketAsync();
      socket?.on('buckets.refillNeeded', (messageRefill) => {
        const bucketsId = JSON.parse(messageRefill);
        if (bucketsId.organizationId === user.organizationId) {
          setToRefill(bucketsId.toRefill);
        }
      });

      socket?.on('buckets.expiredDrugs', (messageExpired) => {
        const bucketsId = JSON.parse(messageExpired);
        if (bucketsId.organizationId === user.organizationId) {
          setExpiredDrugs(bucketsId.expired);
        }
      });

      return () => {
        socket?.off('buckets.refillNeeded');
      };
    };

    waitSocket();
  }, [user.organizationId]);

  return (
    <BrowserRouter>
      {user.isLoggedIn && <SideMenu toggled={toggled} onToggle={() => setToggled(false)} />}
      <Container fluid className="main-container">
        {user.isLoggedIn && <TopMenu onToggle={() => setToggled(true)} />}
        <LoginExpiration />
        <LotExpireSoon />
        <RefillBucketsModal
          isOpen={toRefill.length > 0}
          bucketsId={toRefill}
          editList={setToRefill}
          onClose={() => setToRefill([])}
        />
        <ExpiredDrugsModal
          isOpen={expiredDrugs.length > 0}
          bucketsId={expiredDrugs}
          onClose={() => setExpiredDrugs([])}
        />
        <Row>
          <Col>
            <Switch>
              <Route path={links.paths.login} component={LoginPage} />
              <Route exact path="/">
                <Redirect push to={links.paths.buckets} />
              </Route>
              <Route exact path="/home">
                <Redirect push to={links.paths.buckets} />
              </Route>
              <PrivateRoute
                path={links.paths.buckets}
                component={BucketsPage}
                requiredPermission={UserPermissions.BUCKETS_LIST}
              />
              <PrivateRoute
                path={links.paths.dashboard}
                component={DashboardPage}
                requiredPermission={UserPermissions.ORGANIZATIONS_DASHBOARD}
              />
              <PrivateRoute
                path={links.paths.report}
                component={ReportPage}
                requiredPermission={UserPermissions.ORDERS_REPORTS}
                isAdminOnly
              />
              <Route path={links.paths.userGuide} component={UserGuidePage} />
              <PrivateRoute
                path={links.paths.drugs}
                component={DrugsPage}
                requiredPermission={UserPermissions.DRUGS_LIST}
                isAdminOnly
              />
              <PrivateRoute
                path={links.orders()}
                component={OrdersPage}
                requiredPermission={UserPermissions.ORDERS_LIST}
              />
              <PrivateRoute
                path={links.organizations()}
                component={OrganizationsPage}
                requiredPermission={UserPermissions.ORGANIZATIONS_LIST}
                isAdminOnly
              />
              <PrivateRoute
                path={links.paths.settings}
                component={SettingsPage}
                requiredPermission={UserPermissions.ORGANIZATIONS_SETTINGS}
              />
              <PrivateRoute
                path={links.users()}
                component={UsersPage}
                requiredPermission={UserPermissions.USERS_LIST}
                isAdminOrSuperTech
              />

              <Route path={links.paths.swaggerDoc} />
              <Route component={Page404} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  );
}

export default Layout;
