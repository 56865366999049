import React, { useEffect, useState } from 'react';
import { RootState } from 'services/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../style.scss';
import { BucketNotUsed } from '../types';

interface Props {
  bucketsNotUsed: BucketNotUsed[] | undefined;
  loading: boolean;
}

const BucketsNotUsed: React.FC<Props> = ({ bucketsNotUsed, loading }): JSX.Element => {
  const { t } = useTranslation();
  const [bucketsNotUsedData, setBucketsNotUsedData] = useState<BucketNotUsed[]>([]);
  const [noData, setNoData] = useState<boolean>(false);
  const drugs = useSelector((state: RootState) => state.drugs);

  useEffect(() => {
    if (bucketsNotUsed) {
      setNoData(false);
      if (bucketsNotUsed.length === 0) {
        setNoData(true);
        return;
      }
      const formattedData = bucketsNotUsed.map((bucketNotUsed: BucketNotUsed) => {
        const name = drugs.find((drug) => drug.DIN === bucketNotUsed.DIN)?.name;
        return {
          DIN: bucketNotUsed.DIN,
          position: bucketNotUsed.position,
          name: name || 'N/A',
        };
      });
      setBucketsNotUsedData(formattedData);
    }
  }, [bucketsNotUsed, drugs]);

  return (
    <div className="buckets-not-used-container">
      <div className="componentBase ">
        <div className="buckets-not-used-title">
          <h5>{t('bucketsNotUsed')}</h5>
          <div style={{ fontSize: '10px', fontWeight: 'bold', color: '#b1b1b1' }}>
            ({t('thisMonth')})
          </div>
        </div>
        <hr className="separator" />
        {loading && (
          <div className="spinner" style={{ paddingBottom: '9vh' }}>
            <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
          </div>
        )}
        {!loading && !noData && (
          <ResponsiveContainer width="100%" height="72%" className="scrollable-container">
            <Table responsive="sm" borderless className="buckets-not-used-table">
              <tbody>
                {bucketsNotUsedData.map((bucket, index) => (
                  <tr key={index}>
                    <td style={{ width: '10px' }}>{bucket.position}</td>
                    <td>
                      {bucket.name.length > 14 ? `${bucket.name.substring(0, 14)}...` : bucket.name}{' '}
                      ({bucket.DIN})
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ResponsiveContainer>
        )}
        {!loading && noData && (
          <div className="no-data">
            <p>{t('noDataToDisplay')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BucketsNotUsed;
