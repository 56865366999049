import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const UserGuidePage = () => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputPageNumber, setInputPageNumber] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    setInputPageNumber(pageNumber);
  }, [pageNumber]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (e) => {
    setInputPageNumber(e.target.value);
  };

  const handleGoToPage = () => {
    const newPageNumber = Number(inputPageNumber);
    if (newPageNumber >= 1 && newPageNumber <= numPages) {
      setPageNumber(newPageNumber);
    }
    if (newPageNumber < 1) {
      setPageNumber(1);
    }
    if (newPageNumber > numPages) {
      setPageNumber(numPages);
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      handleGoToPage();
    }
  };

  return (
    <div className="pdf-viewer-container">
      <Document file="../userGuide.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <div style={{ width: '800px', margin: '0 auto' }}>
          <Page pageNumber={pageNumber} width={800} renderAnnotationLayer={false} />
        </div>
      </Document>

      <div className="pdf-controls">
        <Button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}>
          {t('previous')}
        </Button>

        <span>
          {' '}
          <Form.Control
            type="text"
            value={inputPageNumber}
            onChange={handlePageChange}
            onKeyDown={handleEnterKey}
            style={{ width: '50px', display: 'inline-block', marginRight: '5px' }}
          />
          {t('of_sur')} {numPages}
        </span>

        <Button onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= numPages}>
          {t('next')}
        </Button>
      </div>
    </div>
  );
};

export default UserGuidePage;
