import { Bucket, DeactivatedReason } from './types';

export const getCurrentMonth = (): string => new Date().toISOString().slice(0, 7);

export const getNextMonthFromNow = (): string => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return date.toISOString().slice(0, 7);
};

export const addStatusBucket = (buckets: Bucket[], t) => {
  const thisMonth = new Date().toISOString().slice(0, 7);
  return buckets.map((bucket) => {
    let status = t('ACTIVE');

    if (bucket.isDeactivated) {
      switch (bucket.deactivatedReason) {
        case DeactivatedReason.BACK_ORDER:
          status = t('BACKORDER');
          break;
        case DeactivatedReason.ORDERED:
          status = t('ORDEREDCAP');
          break;
        case DeactivatedReason.MAINTENANCE:
          status = t('MAINTENANCECAP');
          break;
        case DeactivatedReason.EXCHANGE:
          status = t('EXCHANGECAP');
          break;
        case DeactivatedReason.EXPIRED_DRUGS:
          status = t('EXPIRED');
          break;
        default:
          status = t('OTHERCAP');
          break;
      }
    } else if (bucket.nearestExpirationDate && bucket.nearestExpirationDate <= thisMonth) {
      status = t('EXPIRED');
    } else if (
      bucket.nearestExpirationDate &&
      bucket.nearestExpirationDate <= getNextMonthFromNow()
    ) {
      status = t('EXPIRINGSOON');
    } else if (bucket.quantity < 25) {
      status = t('LOWQUANTITY');
    } else if (bucket.monthlyQty && bucket.quantity < bucket.monthlyQty * 0.1) {
      status = t('LOWQUANTITY');
    }
    return { ...bucket, status };
  });
};
