import { Drug } from 'components/drugs/types';
import bucketActionType from '../buckets/types';
import types from './types';

export type DrugReducerState = Drug[];

export type DrugAction =
  | { type: types.CREATE_DRUG_REQUEST; payload: { drug: Omit<Drug, 'id' | 'newUPC'> } }
  | { type: types.CREATE_DRUG_RESPONSE; payload: { drug: Drug } }
  | { type: types.GET_DRUGS_REQUEST }
  | { type: types.GET_DRUGS_RESPONSE; payload: { drugs: Drug[] } }
  | { type: types.UPDATE_DRUG_REQUEST; payload: { id: string; drug: Drug } }
  | { type: types.UPDATE_DRUG_RESPONSE; payload: { drug: Drug[] } }
  | { type: types.VALIDATE_DRUG_REQUEST }
  | { type: types.VALIDATE_DRUG_RESPONSE; payload: { drug: Drug } }
  | { type: types.ADD_DRUG_UPC_REQUEST; payload: { id: string; UPC: string } }
  | { type: types.ADD_DRUG_UPC_RESPONSE; payload: { drug: Drug } }
  | { type: types.VALIDATE_DRUG_UPC_REQUEST; payload: { id: string; UPC: string } }
  | { type: types.VALIDATE_DRUG_UPC_RESPONSE; payload: { drug: Drug } }
  | { type: types.INVALIDATE_DRUG_UPC_REQUEST; payload: { id: string; UPC: string } }
  | { type: types.INVALIDATE_DRUG_UPC_RESPONSE; payload: { drug: Drug } }
  | {
      type: types.PATCH_DRUG_REQUEST;
      payload: { id: string; drug: Partial<Drug> };
    }
  | { type: types.PATCH_DRUG_RESPONSE; payload: { drug: Drug[] } }
  | { type: types.DELETE_DRUG_REQUEST; payload: { drugId: string } }
  | { type: types.DELETE_DRUG_RESPONSE; payload: { drug: Drug } }
  | { type: types.IMPORT_DRUGLIST_REQUEST; payload: { drugList: Omit<Drug, 'id' | 'newUPC'>[] } }
  | { type: types.IMPORT_DRUGLIST_RESPONSE; payload: { drugs: Drug[] } }
  | { type: bucketActionType.IMPORT_BUCKETLIST_RESPONSE; payload: { drugs: Drug[] } };

export default function reducers(
  state: DrugReducerState = null!,
  action: DrugAction,
): DrugReducerState {
  switch (action.type) {
    case types.GET_DRUGS_RESPONSE:
      return action.payload.drugs;
    case types.CREATE_DRUG_RESPONSE:
      return (state || []).concat(action.payload.drug);
    case types.VALIDATE_DRUG_RESPONSE:
    case types.ADD_DRUG_UPC_RESPONSE:
    case types.VALIDATE_DRUG_UPC_RESPONSE:
    case types.INVALIDATE_DRUG_UPC_RESPONSE:
      return state?.map((drug) => {
        if (drug.id === action.payload.drug.id) {
          return action.payload.drug;
        }
        return drug;
      });
    case types.UPDATE_DRUG_RESPONSE:
    case types.PATCH_DRUG_RESPONSE:
      return state.map((drug) => {
        const updatedDrug = Object.values(
          action.payload.drug as unknown as { [key: string]: Drug },
        ).find((updated) => updated.id === drug.id);
        return updatedDrug ? { ...drug, ...updatedDrug } : drug;
      });
    case types.DELETE_DRUG_RESPONSE:
      return state?.filter((drug) => drug.id !== action.payload.drug.id);
    case types.IMPORT_DRUGLIST_RESPONSE:
    case bucketActionType.IMPORT_BUCKETLIST_RESPONSE:
      const newState = state?.length > 0 ? [...state] : [];
      action.payload.drugs.forEach((updatedDrug) => {
        const index = newState.findIndex((drug) => drug.DIN === updatedDrug.DIN);
        if (index !== -1) {
          newState[index] = updatedDrug;
        } else {
          newState.push(updatedDrug);
        }
      });
      return newState;
    default:
      return state;
  }
}
